import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { ThreeDots } from 'react-loader-spinner'

import "./index.css";

const Dashboard = () => {
  const navigate = useNavigate();

  const [carddata, setcardData] = useState([]);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const PendingTicketscolumns = [
    {
      name: (
        <p className='font-[700] text-[#060606]  text-[20px] md:text-[20px] font-poppins'>
          #
        </p>
      ),

      width: "100px",

      cell: (row, index) => index + 1,
    },

    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          User Name
        </p>
      ),
      wrap: false,

      selector: (row) => row.name,
    },
    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          Type
        </p>
      ),
      wrap: false,

      selector: (row) => row.type,
    },
    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          Created Timestamp
        </p>
      ),
      wrap: false,

      selector: (row) => moment(row.created_At).format("YYYY-MM-DD HH:mm:ss"),

      grow: 2,
    },

    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          Status
        </p>
      ),
      wrap: false,

      cell: (row) => (
        <div className='my-2'>
          {
            <button className='p-[12px] bg-[#FBD7D7] text-[14px] font-[600] text-[#B53434]  w-[120px]'>
              Pending
            </button>
          }
        </div>
      ),
    },
    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px]  font-poppins'>
          Actions
        </p>
      ),

      cell: (row) => (
        <div className='flex gap-5 '>
          <div className='delete cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='33'
              viewBox='0 0 32 33'
              fill='none'>
              <path
                d='M9.33203 28.5C8.5987 28.5 7.97114 28.2391 7.44936 27.7173C6.9267 27.1947 6.66536 26.5667 6.66536 25.8333V8.5C6.28759 8.5 5.9707 8.37244 5.7147 8.11733C5.45959 7.86133 5.33203 7.54444 5.33203 7.16667C5.33203 6.78889 5.45959 6.472 5.7147 6.216C5.9707 5.96089 6.28759 5.83333 6.66536 5.83333H11.9987C11.9987 5.45556 12.1267 5.13867 12.3827 4.88267C12.6378 4.62756 12.9543 4.5 13.332 4.5H18.6654C19.0431 4.5 19.36 4.62756 19.616 4.88267C19.8711 5.13867 19.9987 5.45556 19.9987 5.83333H25.332C25.7098 5.83333 26.0263 5.96089 26.2814 6.216C26.5374 6.472 26.6654 6.78889 26.6654 7.16667C26.6654 7.54444 26.5374 7.86133 26.2814 8.11733C26.0263 8.37244 25.7098 8.5 25.332 8.5V25.8333C25.332 26.5667 25.0711 27.1947 24.5494 27.7173C24.0267 28.2391 23.3987 28.5 22.6654 28.5H9.33203ZM11.9987 21.8333C11.9987 22.2111 12.1267 22.5276 12.3827 22.7827C12.6378 23.0387 12.9543 23.1667 13.332 23.1667C13.7098 23.1667 14.0267 23.0387 14.2827 22.7827C14.5378 22.5276 14.6654 22.2111 14.6654 21.8333V12.5C14.6654 12.1222 14.5378 11.8053 14.2827 11.5493C14.0267 11.2942 13.7098 11.1667 13.332 11.1667C12.9543 11.1667 12.6378 11.2942 12.3827 11.5493C12.1267 11.8053 11.9987 12.1222 11.9987 12.5V21.8333ZM17.332 21.8333C17.332 22.2111 17.46 22.5276 17.716 22.7827C17.9711 23.0387 18.2876 23.1667 18.6654 23.1667C19.0431 23.1667 19.36 23.0387 19.616 22.7827C19.8711 22.5276 19.9987 22.2111 19.9987 21.8333V12.5C19.9987 12.1222 19.8711 11.8053 19.616 11.5493C19.36 11.2942 19.0431 11.1667 18.6654 11.1667C18.2876 11.1667 17.9711 11.2942 17.716 11.5493C17.46 11.8053 17.332 12.1222 17.332 12.5V21.8333Z'
                fill='#B53434'
              />
            </svg>
          </div>

          <div
            className='edit cursor-pointer'
            onClick={() => navigate("/editevent")}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='33'
              viewBox='0 0 32 33'
              fill='none'>
              <path
                d='M25.7333 12.4003L20.0667 6.80033L21.9333 4.93366C22.4444 4.42255 23.0724 4.16699 23.8173 4.16699C24.5622 4.16699 25.1898 4.42255 25.7 4.93366L27.5667 6.80033C28.0778 7.31144 28.3444 7.92833 28.3667 8.65099C28.3889 9.37366 28.1444 9.9901 27.6333 10.5003L25.7333 12.4003ZM23.8 14.367L9.66667 28.5003H4V22.8337L18.1333 8.70033L23.8 14.367Z'
                fill='#060606'
              />
            </svg>
          </div>
        </div>
      ),
    },
  ];

  const pendingTicketsData = [
    {
      name: "Sudheer Kumar",
      type: "Payment Related",
      status: "pending",
    },
    {
      name: "Prathap",
      type: "Corporate Events",
      status: "pending",
    },
    {
      name: "Sudheer Kumar",
      type: "Manikanta",
      status: "pending",
    },
    {
      name: "Akhilesh",
      type: "Private Events",

      status: "pending",
    },
  ];

  useEffect(() => {
    getcarddata();
    getalltickets();
    getallevents();
  }, []);

  const getcarddata = async () => {
    setLoader(true);
    try {
      const result = await axios.get("http://localhost:4000/dashboarddata");

      if (
        result.status === 400 ||
        result.status === 404 ||
        result.status === 409 ||
        result.status === 500
      ) {
        setLoader(false);
        return console.log("card data not available");
      }

      setLoader(false);
      setcardData(result.data.response.data);
    } catch (error) {
      setLoader(false);
      console.log("Something went wrong while finding card");
      return console.log("card data fetched failed");
    }
  };

  const getalltickets = async () => {
    setLoader(true);
    try {
      const result = await axios.get("http://localhost:4000/getpendingtickets");

      if (
        result.status === 400 ||
        result.status === 404 ||
        result.status === 409 ||
        result.status === 500
      ) {
        setLoader(false);
        return console.log("no tickets available");
      }

      setLoader(false);
      setData(result.data.response.tickets);
    } catch (error) {
      setLoader(false);
      console.log("Something went wrong while finding tickets");
      return console.log("ticket fetched failed");
    }
  };

  // event columns

  const eventColumns = [
    {
      name: (
        <p className='font-[700] text-[#060606]  text-[20px] md:text-[20px] font-poppins'>
          #
        </p>
      ),

      width: "100px",

      cell: (row, index) => index + 1,
    },

    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          Event Name
        </p>
      ),
      id: "eventName",
      wrap: false,

      selector: (row) => row.eventName,
    },

    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          Created Timestamp
        </p>
      ),
      wrap: false,

      selector: (row) => moment(row.created_At).format("YYYY-MM-DD HH:mm:ss"),

      grow: 2,
    },
    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px] font-poppins'>
          Updated Timestamp
        </p>
      ),
      wrap: false,

      selector: (row) => moment(row.created_At).format("YYYY-MM-DD HH:mm:ss"),

      grow: 2,
    },

    {
      name: (
        <p className='font-[700] text-[#060606]  text-[20px] font-poppins'>
          Status
        </p>
      ),
      wrap: false,
      selector: (row) => row.status,

      cell: (row) => (
        <div className='my-2'>
          {
            <button
              className={`p-[12px] ${
                row.status === "pending"
                  ? "bg-[#FBD7D7] text-[#B53434]"
                  : "bg-[#E2FBD7] text-[#34b43A]"
              }   text-[14px] font-[600]  `}>
              {row.status === "pending" ? "pending" : "upcoming"}
            </button>
          }
        </div>
      ),
    },
    {
      name: (
        <p className='font-[700] text-[#060606] text-[20px]  font-poppins'>
          Actions
        </p>
      ),

      cell: (row) => (
        <div className='flex gap-5 '>
          <div className='delete cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='33'
              viewBox='0 0 32 33'
              fill='none'>
              <path
                d='M9.33203 28.5C8.5987 28.5 7.97114 28.2391 7.44936 27.7173C6.9267 27.1947 6.66536 26.5667 6.66536 25.8333V8.5C6.28759 8.5 5.9707 8.37244 5.7147 8.11733C5.45959 7.86133 5.33203 7.54444 5.33203 7.16667C5.33203 6.78889 5.45959 6.472 5.7147 6.216C5.9707 5.96089 6.28759 5.83333 6.66536 5.83333H11.9987C11.9987 5.45556 12.1267 5.13867 12.3827 4.88267C12.6378 4.62756 12.9543 4.5 13.332 4.5H18.6654C19.0431 4.5 19.36 4.62756 19.616 4.88267C19.8711 5.13867 19.9987 5.45556 19.9987 5.83333H25.332C25.7098 5.83333 26.0263 5.96089 26.2814 6.216C26.5374 6.472 26.6654 6.78889 26.6654 7.16667C26.6654 7.54444 26.5374 7.86133 26.2814 8.11733C26.0263 8.37244 25.7098 8.5 25.332 8.5V25.8333C25.332 26.5667 25.0711 27.1947 24.5494 27.7173C24.0267 28.2391 23.3987 28.5 22.6654 28.5H9.33203ZM11.9987 21.8333C11.9987 22.2111 12.1267 22.5276 12.3827 22.7827C12.6378 23.0387 12.9543 23.1667 13.332 23.1667C13.7098 23.1667 14.0267 23.0387 14.2827 22.7827C14.5378 22.5276 14.6654 22.2111 14.6654 21.8333V12.5C14.6654 12.1222 14.5378 11.8053 14.2827 11.5493C14.0267 11.2942 13.7098 11.1667 13.332 11.1667C12.9543 11.1667 12.6378 11.2942 12.3827 11.5493C12.1267 11.8053 11.9987 12.1222 11.9987 12.5V21.8333ZM17.332 21.8333C17.332 22.2111 17.46 22.5276 17.716 22.7827C17.9711 23.0387 18.2876 23.1667 18.6654 23.1667C19.0431 23.1667 19.36 23.0387 19.616 22.7827C19.8711 22.5276 19.9987 22.2111 19.9987 21.8333V12.5C19.9987 12.1222 19.8711 11.8053 19.616 11.5493C19.36 11.2942 19.0431 11.1667 18.6654 11.1667C18.2876 11.1667 17.9711 11.2942 17.716 11.5493C17.46 11.8053 17.332 12.1222 17.332 12.5V21.8333Z'
                fill='#B53434'
              />
            </svg>
          </div>

          <div
            className='edit cursor-pointer'
            onClick={() => navigate("/editevent")}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='33'
              viewBox='0 0 32 33'
              fill='none'>
              <path
                d='M25.7333 12.4003L20.0667 6.80033L21.9333 4.93366C22.4444 4.42255 23.0724 4.16699 23.8173 4.16699C24.5622 4.16699 25.1898 4.42255 25.7 4.93366L27.5667 6.80033C28.0778 7.31144 28.3444 7.92833 28.3667 8.65099C28.3889 9.37366 28.1444 9.9901 27.6333 10.5003L25.7333 12.4003ZM23.8 14.367L9.66667 28.5003H4V22.8337L18.1333 8.70033L23.8 14.367Z'
                fill='#060606'
              />
            </svg>
          </div>
        </div>
      ),
    },
  ];

  const [eventdata, seteventData] = useState([]);



  const getallevents = async () => {
    setLoader(true);
    try {
      const result = await axios.get("http://localhost:4000/getupcomingevent");

      if (
        result.status === 400 ||
        result.status === 404 ||
        result.status === 409 ||
        result.status === 500
      ) {
        setLoader(false);
        return console.log("no events available");
      }

      setLoader(false);
      seteventData(result.data.response.events);
    } catch (error) {
      setLoader(false);
      console.log("Something went wrong while finding event");
      return console.log("event fetched failed");
    }
  };

  const eventsData = [
    {
      eventName: "Kurta Panjama",
      status: "upcoming",
    },
    {
      eventName: "Kurta Panjama",
      status: "upcoming",
    },
    {
      eventName: "Kurta Panjama",
      status: "upcoming",
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        fontWeight: "bold",
        border:"none"
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid var(--Stroke, #808080)",

        color: "#060606",
        padding: "10px 20px 10px 0px",
        marginBottom: "10px",

        fontSize: "20px",
      

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        fontWeight: "800",
        height: "75px",
      },
    },
    header: {
      style: {},
    },
    rows: {
      style: {
        borderTop: "0.824px solid var(--Stroke, #808080)",

        color: "#060606",
        padding: "10px 20px 10px 0px",
        backgroundColor: "transparent",
        
        marginBottom:"-1px",
        

        fontSize: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        fontWeight: "600",
        overflow:"hidden",
        height:"75px"
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "10px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
        borderCollapse: "collapse",
      },
    },
    headCells: {
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    cells: {
      style: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
       
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
  };

  return (
    <>
      {loader ? (
        <div className='w-full h-screen'>
          <div className='w-full h-[400px] flex justify-center items-center'>
            {" "}
            <ThreeDots
              height='80'
              width='130'
              radius='9'
              color='#0074be'
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              wrapperClassName=''
              visible={true}
            />{" "}
          </div>
        </div>
      ) : (
        <div className='  bg-[#F6F6F6] mt-0'>
          <div className='py-4'>
            <div className='flex flex-row w-full element overflow-x-auto px-6 py-6 bg-transparent hide-scrollbar  scroll-smooth gap-5  '>
              <div
                style={{ boxShadow: "0px 10px 30px  0px rgba(0, 0, 0, 0.15)" }}
                className='h-[89px] w-[185px] md:h-[130px] md:w-[213px] bg-[#fff] flex flex-col justify-center items-center gap-3 md:gap-6 drop-shadow-lg shrink-0 '>
                <p className='text-[16px] font-poppins text-[#808080] font-[700] leading-[1rem] '>
                  Total Events
                </p>
                <h1 className='text-[#060606] text-[24px] md:text-[36px] font-poppins font-[800] leading-[1rem]'>
                  8
                </h1>
              </div>

              <div
                style={{ boxShadow: "0px 10px 30px  0px rgba(0, 0, 0, 0.15)" }}
                className='h-[89px] w-[185px] md:h-[130px] md:w-[213px] bg-[#fff] flex flex-col justify-center items-center drop-shadow-lg gap-3 md:gap-6 shrink-0 '>
                <p className='text-[16px] font-poppins text-[#808080] font-[700] leading-[1rem] '>
                  Total Events Done
                </p>
                <h1 className='text-[#060606] text-[24px] md:text-[36px] font-poppins font-[800] leading-[1rem]'>
                  15
                </h1>
              </div>
              <div
                style={{ boxShadow: "0px 10px 30px  0px rgba(0, 0, 0, 0.15)" }}
                className='h-[89px] w-[185px] md:h-[130px] md:w-[213px] bg-[#fff] flex flex-col justify-center items-center drop-shadow-lg gap-3 md:gap-6 shrink-0 '>
                <p className='text-[16px] font-poppins text-[#808080] font-[700] leading-[1rem] '>
                  Total Registrations
                </p>
                <h1 className='text-[#060606] text-[24px] md:text-[36px] font-poppins font-[800] leading-[1rem]'>
                  500
                </h1>
              </div>
              <div
                style={{ boxShadow: "0px 10px 30px  0px rgba(0, 0, 0, 0.15)" }}
                className='h-[89px] w-[185px] md:h-[130px] md:w-[213px] bg-[#fff] flex flex-col justify-center items-center drop-shadow-lg gap-3 md:gap-6 shrink-0 '>
                <p className='text-[16px] font-poppins text-[#808080] font-[700] leading-[1rem] '>
                  Support Tickets
                </p>
                <h1 className='text-[#060606] text-[24px] md:text-[36px] font-poppins font-[800] leading-[1rem]'>
                  9
                </h1>
              </div>
              <div
                style={{ boxShadow: "0px 10px 30px  0px rgba(0, 0, 0, 0.15)" }}
                className='h-[89px] w-[185px] md:h-[130px] md:w-[213px] bg-[#fff] flex flex-col justify-center items-center drop-shadow-lg gap-3 md:gap-6 shrink-0 '>
                <p className='text-[16px] font-poppins text-[#808080] font-[700] leading-[1rem] '>
                  Total Revenue
                </p>
                <h1 className='text-[#060606] text-[24px] md:text-[36px] font-poppins font-[800] leading-[1rem]'>
                  ₹ 7.5 L
                </h1>
              </div>
            </div>

            <div
              style={{
                boxShadow:
                  "0px 0px 15px 0px #ddd, 0px 4px 16px rgba(0, 0, 0, 0.25) ",
              }}
              className='mt-6 mx-6 my-6 md:mr-8'>
              <div className='flex flex-row justify-between items-center h-[72px] md:h-[90px] px-4 md:px-6 bg-[#fff]'>
                <h1 className='font-poppins text-[20px] md:text-[32px] font-[600] leading-6 text-[#060606]'>
                  Pending Tickets
                </h1>

                <button className='h-[40px] w-[143px] md:w-[153px] md:h-[56px]  border border-[#808080] flex flex-row justify-center items-center gap-4 '>
                  <span className='font-poppins text-[#060606] text-[15px] font-[700] leading-[99%]'>
                    show all
                  </span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'>
                    <path
                      d='M8.8742 21.1004L17.2992 12.7004C17.3992 12.6004 17.4702 12.4921 17.5122 12.3754C17.5542 12.2587 17.5749 12.1337 17.5742 12.0004C17.5742 11.8671 17.5535 11.7421 17.5122 11.6254C17.4709 11.5087 17.3999 11.4004 17.2992 11.3004L8.8742 2.87539C8.64087 2.64206 8.3492 2.52539 7.9992 2.52539C7.6492 2.52539 7.3492 2.65039 7.0992 2.90039C6.8492 3.15039 6.7242 3.44206 6.7242 3.77539C6.7242 4.10872 6.8492 4.40039 7.0992 4.65039L14.4492 12.0004L7.0992 19.3504C6.86587 19.5837 6.7492 19.8714 6.7492 20.2134C6.7492 20.5554 6.8742 20.8511 7.1242 21.1004C7.3742 21.3504 7.66587 21.4754 7.9992 21.4754C8.33254 21.4754 8.6242 21.3504 8.8742 21.1004Z'
                      fill='#060606'
                    />
                  </svg>
                </button>
              </div>

              <div className=''>
                <DataTable
                  columns={PendingTicketscolumns}
                  data={pendingTicketsData}
                  responsive
                  customStyles={customStyles}
                />
              </div>
            </div>

            <div
              style={{
                boxShadow:
                  "0px 0px 15px 0px #ddd, 0px 4px 16px rgba(0, 0, 0, 0.25) ",
              }}
              className='mt-10 mx-6 my-6 md:mr-8'>
              <div className='flex flex-row justify-between items-center h-[72px] md:h-[90px] px-4 md:px-6 bg-[#fff]'>
                <h1 className='font-poppins text-[20px] md:text-[32px] font-[600] leading-6 text-[#060606]'>
                  Manage Events
                </h1>
                <div className='flex flex-row gap-4'>
                  <button
                    style={{ border: "1px solid var(--Stroke, #808080)" }}
                    className='w-[40px] h-[40px] flex flex-row justify-center items-center md:hidden'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'>
                      <path
                        d='M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.4217 2.75 18.8923 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.655 21.1083 7.11733 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z'
                        fill='#060606'
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => navigate("/addevent", { relative: "path" })}
                    className='hidden  md:flex md:flex-row md:justify-center md:items-center md:w-[200px] md:h-[56px]  border border-[#808080]  gap-4 '>
                    <span className='font-poppins text-[#060606] text-[15px] font-[700] leading-[99%]'>
                      Create Event
                    </span>

                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'>
                      <path
                        d='M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.4217 2.75 18.8923 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.655 21.1083 7.11733 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z'
                        fill='#060606'
                      />
                    </svg>
                  </button>

                  <button className='h-[40px] w-[143px] md:w-[153px] md:h-[56px]  border border-[#808080] flex flex-row justify-center items-center gap-4 '>
                    <span className='font-poppins text-[#060606] text-[15px] font-[700] leading-[99%]'>
                      show all
                    </span>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'>
                      <path
                        d='M8.8742 21.1004L17.2992 12.7004C17.3992 12.6004 17.4702 12.4921 17.5122 12.3754C17.5542 12.2587 17.5749 12.1337 17.5742 12.0004C17.5742 11.8671 17.5535 11.7421 17.5122 11.6254C17.4709 11.5087 17.3999 11.4004 17.2992 11.3004L8.8742 2.87539C8.64087 2.64206 8.3492 2.52539 7.9992 2.52539C7.6492 2.52539 7.3492 2.65039 7.0992 2.90039C6.8492 3.15039 6.7242 3.44206 6.7242 3.77539C6.7242 4.10872 6.8492 4.40039 7.0992 4.65039L14.4492 12.0004L7.0992 19.3504C6.86587 19.5837 6.7492 19.8714 6.7492 20.2134C6.7492 20.5554 6.8742 20.8511 7.1242 21.1004C7.3742 21.3504 7.66587 21.4754 7.9992 21.4754C8.33254 21.4754 8.6242 21.3504 8.8742 21.1004Z'
                        fill='#060606'
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div className=''>
                <DataTable
                  columns={eventColumns}
                  data={eventsData}
                  responsive
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard